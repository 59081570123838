<template>
    <div class="about">
        <head-img desc="关于我们" :imgUrl="aboutBgUrl"></head-img>
        <my-menu :titleList="titleList" :menuList="menuList"></my-menu>
        <div class="about-content">
            <div class="content">
                <div class="text" v-html="information.detailsContent"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            aboutBgUrl: require('@/assets/img/home/lunbo3.jpg'),
            menuList: [
                {name: '首页', path: '/'},
                {name: '关于我们'},
            ],
            titleList: [
                {name: '关于我们'},
            ],
            id: '',
            information: ''
        }
    },
    watch:{
        $route(to){
            console.log(to.path)
            if(to.path === '/about'){
                console.log(this.$route.query.id)
                this.id = this.$route.query.id
                this.getDetail()
            }
        }
    },
    methods:{
        goContact() {
            this.$router.push('/contact')
        },
        getDetail() {
            this.$store.commit('loading')
            this.$api.post('bill/article/article-showForHomePage', {id: this.id}).then(res=>{
                console.log(res)
                this.information = res.data
                this.titleList[0].name = res.data.articleName
                this.menuList[1].name = res.data.articleName
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }
    },
    mounted() {
        if(this.$route.query.name) {
            this.titleList[0].name = this.$route.query.name
            this.menuList[1].name = this.$route.query.name
            this.id = this.$route.query.id
        }else{
            //关于我们
            this.id = '5867f0545288ebdc0a5fc36c8f0dec23'
        }
        this.getDetail()
        console.log(this.$route.query.name)
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .about-content{
        display: flex;
        justify-content: center;
        
        .content{
            width: 1200px;
            .text{
                min-height: 160px;
                padding: 30px 0;
            }

        }
    }

</style>



